<template>
    <HeaderComp />
    <v-main class="d-flex align-center justify-center">
        <router-view />
    </v-main>
    <!-- <footer class="d-flex"> -->
    <FoterComp />
    <!-- </footer> -->
</template>

<script>
import HeaderComp from "../components/HeaderComp/HeaderComp.vue";
import FoterComp from "../components/FooterComp/FooterComp.vue"
import "./HomeView.scss";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "layout",
  components: {
      HeaderComp,
      FoterComp
  },
  mounted() {
    // this.$router.push('/start')
  },
  computed: {
    ...mapGetters(['CURENT_LANG']),
    ...mapState(['lang']),
  },    
}
</script>

<style>

</style>