<template>
  <v-main>
    <v-row class="d-flex justify-center pt-10">
        <v-col cols="8">
            <h1 class="text-h4">
                Website Privacy Policy
            </h1>
            <p>                
                Protecting privacy and personal data
                NitrosData is committed to protecting your privacy and personal data. NitrosData applies a high level of protection of your privacy and personal data, and processes your personal data in accordance with applicable legislation and this Privacy Policy. “Personal data” means any information relating to an identified or identifiable natural person. This Privacy Policy provides you general information on how NitrosData processes your personal data related to the website www.nitrosbase.com or any of its sub-sites (hereinafter together referred to as “Site”).
            </p>
            <p>
                The Site, NitrosData’s products or services may contain links to other companies’ websites and services that have their own privacy policies and/or the services of third parties installed on your device may allow access to information on your device. NitrosData advises you to read carefully the privacy policies of such third parties. NitrosData is not responsible for such third party service providers’ privacy practices or contents.
            </p>
            <p>
                By using the Site and/or by submitting personal data to NitrosData, you understand and agree that your personal data may be processed as explained in this Privacy Policy. If you do not accept this Privacy Policy, NitrosData advises you not to use the Site or submit any personal data to NitrosData.
            </p>
            <p>
				1. Controller of Personal Data
            </p>
            <p>
				The controller for your personal data is NitrosData Corporation; for local country-specific NitrosData websites, jointly with its local NitrosData subsidiary (together “NitrosData”). You can reach the data controller at:
            </p>
            <p>
                42, Bolshoy Blvd.1, office 336,
                Moscow, 143026, Russia
            </p>
            <p>
				2. Purpose of the processing of personal data and legal basis for processing
            </p>
            <p>
				When using the Site, certain personal data that can be connected to you may be collected and processed.
            </p>
            <p>
				Such personal data may be used for the following purposes, as necessary for NitrosData’s legitimate interests in connection to (one or more of which may apply simultaneously):
            </p>
			<p>
                maintenance and development of the Site and NitrosData products and services;
                marketing and communication purposes such as for conducting marketing research, direct marketing, automated marketing, informing you of new features, new products or launches, and special promotions;
                provision of products and services such as for performing the contract between you and NitrosData, ensuring the functionality and security of NitrosData products and services, identifying you and preventing and investigating possible misuses; and for
                statistical and analytical purposes.
                If you communicate with us using the Site, and/or register as a user of the Site (where applicable), NitrosData may in some instances also process your personal data on the basis that such processing is necessary for the performance of a contract between you and NitrosData, preparatory measures in connection to the contract, or, in some instances, based on your consent.
            </p>
            <p>
				To the extent NitrosData processes your personal data based on your consent, you have the right, at any time and free of charge, to withdraw the consent that you give for NitrosData to process your personal data. Please, note however, that such withdrawal may mean that you will no longer be able to use all the functionalities on the Site, or other products or services offered by NitrosData to you, since certain data processing activities relying on your consent may be an integral part of such functionalities, products or services.
            </p>
            <p>
				3. Collected Personal Data
            </p>
            <p>
				Personal data is typically collected when you use the Site, register into NitrosData’s products and services or otherwise interact with NitrosData. The personal data may include, among others, access times, the website you linked from, the links you use, the pages you visit, the content you view and such other behavioural information NitrosData receives through your browser.
            </p>
            <p>
				NitrosData may further ask you to provide NitrosData with other kind of information, such as your name, e-mail address, street address, user names and passwords, consent, feedback, age, gender and other non-sensitive data. NitrosData wishes to emphasize that certain non-identifiable personal data may become identifiable when you provide NitrosData with your personal data.
            </p>
            <p>
				4. Regular sources of data
            </p>
            <p>
				The personal data is collected from the following sources:
            </p>
            <p>
                - information provided by you or through your actions within the Site or otherwise contacting NitrosData;
                - NitrosData’s subcontractors in relation to the provision of services with respect to the Site
                - Publicly available sources
            </p>
            <p>
				5. Regular disclosure of data and the transfer of data outside the EU or the EEA
            </p>
            <p>
                The personal data may be disclosed by NitrosData to its group companies also outside the European Union (“EU”) or outside the European Economic Area (“EEA”) in accordance with a contract entered into between the relevant NitrosData entities, incorporating the European Commission’s Standard Contractual Clauses, which ensure that adequate data protection arrangements are in place. The personal data stored on the server will not be transferred outside the EU or the EEA.
            </p>
            <p>
                NitrosData may disclose your personal data to authorized third parties who process personal data on NitrosData’s behalf for the purposes stated in this Privacy Policy. Your personal data may be processed by such authorized third parties also outside EU or EEA in accordance with a contract entered into between NitrosData and such authorized third party, incorporating the European Commission’s Standard Contractual Clauses, which ensure that adequate data protection arrangements are in place.
            </p>
            <p>
                NitrosData may further conduct marketing and other communication jointly with NitrosData’s partners and in connection with this, NitrosData may combine information NitrosData has collected about you with information NitrosData’s partners have collected about you to e.g. avoid unnecessary communication and tailor NitrosData’s message to you.
            </p>
            <p>
				In the event NitrosData sells, buys, merges or reorganizes in some other way, this may involve NitrosData disclosing personal data e.g. to potential and actual purchasers and their advisers.
            </p>
            <p>
                In addition to the above NitrosData may be obliged to disclose your personal data to the authorities. NitrosData may also disclose and otherwise process your personal data, in accordance with applicable legislation, to defend its legitimate interests.
            </p>
            <p>
                6. Principles of data security and data storage
            </p>
            <p>
                The personal data will be mainly stored electronically. The personal data is stored in locked premises with restricted access and protected by a participant ID and a password. All physical copies of the personal data, if any, will be stored in locked premises. Only persons who need to process the personal data as part of their duties have access to and the right to process the information stored in the register.
            </p>
            <p>
                NitrosData retains your personal data as long as it is necessary for the aforementioned purposes or as required by applicable laws. When such requirements no longer exist, your personal data will be deleted or anonymised in such a manner that it no longer can be connected to you.
            </p>
            <p>
				7. Automated decision making
            </p>
            <p>
				Personal data is not used for automated decision making having legal or similar consequences to you.
            </p>
            <p>
                8. User’s rights
            </p>
            <p>
                Right of access: You are entitled to inspect your personal data and to receive a written copy of the personal data. The inspection is free of charge.
            </p>
            <p>
                Right to data portability: To the extent NitrosData processes your personal data based on your consent, or for the purposes of a contract between you and NitrosData, you are entitled to receive your personal data, which you have provided to NitrosData, in a structured, commonly used and machine-readable format and, when technically feasible, have the right to transmit those data to another controller without hindrance from NitrosData.
            </p>
            <p>
                Right to object: You are entitled to object at any time to processing of your personal data for direct marketing purposes.
            </p>
            <p>
                Right to request rectification, erasure or restriction: You are entitled to ask NitrosData to rectify or erase your personal data in case it is obsolete, irrelevant, incorrect, incomplete, otherwise inaccurate or due to other legally acceptable ground. You are also entitled to request restriction of processing of your personal data.
            </p>
            <p>
                Official complaint: while NitrosData hopes to resolve any issue you may have with how NitrosData processes your personal data, you do also have the right to lodge an official complaint with your local national data protection supervisory authority.
            </p>
            <p>
                If you wish to exercise your rights, please present a written request to the address stated in Section 11.
            </p>
            <p>
                9. Use of Cookies
            </p>
            <p>
                A cookie is typically a small piece of data sent to your computer device from a website and stored in your web browser while you are browsing a website. When you browse the same website again, the data stored in the cookie may be retrieved by the website to notify the website of your previous activity. To read more about these technologies and how they work, please see for example <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>.
            </p>
            <p>
                From time to time, a 'cookie' file may be placed on your computer to improve the effectiveness of this Site and/or NitrosData’s services to you. Most browsers are set to accept cookies. You can change your settings to refuse all cookies or to tell you when a cookie is being sent.
            </p>
            <p>
                This Privacy Policy details how NitrosData utilizes cookies and cookie-like files and technologies on the Site. If you visit the Site and your browser settings accept cookies, NitrosData considers this as an express consent of NitrosData’s use of cookies.
            </p>
            <p>
                Purposes of use of cookies
                The use of certain cookies is necessary to run basic functions on the Site. These cookies are essential for running the Site and are needed to provide you a pleasant experience when visiting the Site.
            </p>
			<p>
                NitrosData may also use cookies to provide better services and products to you. These cookies are used for e.g. site improvement, personalization and advertising. From your point of view this means that the Site may remember e.g. the language and country choices you have made. NitrosData does also analytical profiling to discover e.g. what is popular among users. NitrosData looks at where the content of the Site is accessed to help NitrosData arrange the Site for best possible user experience. NitrosData also counts clicks on “Like” and “Tweet” plugins and what content of the Site was shared or referenced. These cookies help make the content of the Site personalized, for example by showing you targeted banners and recommendations. NitrosData also looks at what kinds of references are used to reach the Site in order to evaluate the effectiveness of NitrosData’s campaigns and promotions. NitrosData uses these cookies to serve NitrosData ads from NitrosData.
            </p>
            <p>
                The length of storage of information depends on the type of cookie used. Session cookies expire when the browser is closed, while persistent cookies have typical expiration dates between two months and a couple of years.
            </p>
            <p>
                10. Website Analytics and Visitor Information
            </p>
            <p>
                The Site uses a web analytics service provided, for example, by Google, Inc (“Google”). To read a statement on the web analytics service by Google, please click here: . By using the Site, you consent to the processing of data about you by Google in the manner and for the purposes set out in the above statement.
            </p>
            <p>
				Third parties, such as advertisers, advertising networks and providers of tracking services, may set cookies on your device when you are visiting the Site, for example to provide you with targeted advertising or to compile statistics on the number of visitors on the Site. Since your web browser must request these advertisements from third parties’ servers, these third parties may view, modify or set their own cookies as if you had requested a webpage from the third parties’ website directly.
            </p>
            <p>
				The Site uses social media plugins, such as the “Like” button provided by the social network facebook.com. The content of the social media plugins on the Site is from such social network(s) directly. When you visit the Site while logged into a social network, your browser establishes a direct connection with the social network’s servers. If you are logged out from the social network(s) while visiting Site, your browser sends the social network(s) a more limited information. The social media plugins on the Site are subject to the privacy terms and other terms and conditions of the relevant social media sites. The information collected by the social network(s) is not disclosed to NitrosData without your explicit consent.
            </p>
            <p>
              11. Changes to this Privacy Policy
            </p>
            <p>
				Please be aware that NitrosData may change this Privacy Policy from time to time. However, in the event of any material, adverse changes, NitrosData will post a notice informing of such changes both at the beginning of this Privacy Policy and on the Site’s home page. NitrosData advises you to visit this Privacy Policy from time to time to be aware of such changes.
            </p>
            <p>
                Should you have any additional questions, please don’t hesitate to contact us through our customer service or by writing us to: <a href="mailto:info@nitrosbase.com">info@nitrosbase.com</a>.
            </p>
        </v-col>
    </v-row>
  </v-main>
  <footer class="d-flex bg-footer">
      <FooterComp class="w-100"/>
  </footer>
</template>

<script>
import FooterComp from "../../components/FooterComp/FooterComp.vue";
export default {
    name: "PrivacyPolicy",
    components: {
        FooterComp
    },
}
</script>

<style>

</style>