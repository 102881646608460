<template>
  <v-navigation-drawer
    v-model="drawer"
    location="bottom"
    :width="100"
    class="d-flex"
    disable-resize-watcher
    hide-overlay
    app
  >
    <p>
        This website uses cookies to give you the best possible user experience. By continuing to use the website without changing your browser settings you agree to the use of cookies. For further information on cookies, please read our        
        <a href="#" @click.prevent="$router.push('/privacyPolicy')"> privacy policy.</a> 
    </p>
    <v-btn @click="acceptCookie()">Agree</v-btn>
  </v-navigation-drawer>
</template>

<script>
import "./CoockieWindow.scss";
export default {    
  name: "CoockieWindow",
  data() {
      return {
          drawer: true,
      }
  },
  created() {
    console.log(document.cookie);
    if (document.cookie) {
      this.drawer = false
    }
  },
  methods: {
    acceptCookie() {
      document.cookie = "accept=yes;"
      // this.$cookie.set("accept","yes",10)
      this.drawer = false
      console.log(document.cookie);
    }
  }
}
</script>

<style>

</style>