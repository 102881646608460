<template>
  <v-row class="d-flex flex-column">
    <v-col cols="12">
      <v-row class="d-flex justify-center">
        <h1 @click="$router.push('/start'),setPage('start')">Im</h1>
      </v-row>
      <v-row class="d-flex justify-center inmemory-info">
        <v-col cols="8" class="pa-0 d-flex flex-column">
          <h1 class="info-title">NitrosBase InMemory DB</h1>
          <p v-if="GET_LANG == 'ru'" class="info-txt database mt-5 ">ВЫСОКОПРОИЗВОДИТЕЛЬНАЯ БИБЛИОТЕКА ДЛЯ РАБОТЫ С ДАННЫМИ В ПАМЯТИ</p>
          <p v-if="GET_LANG == 'en'" class="info-txt mt-5">HIGH-PERFORMANCE IN-MEMORY DATA PROCESSING LIBRARY</p>
          <p v-if="GET_LANG == 'ru'" class="info-txt2 mt-2 ">Резидентная база данных</p>
          <p v-if="GET_LANG == 'en'" class="info-txt2 mt-2">In-Memory database</p>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center inmemory-dignity">
        <v-col cols="8" class="pa-0 d-flex flex-column">          
          <div class="section-container wrapper">
            <div class="dignity-container">
              <div class="dignity-item">
                <div class="content">
                  <v-img class="top-img" width="146" src="../../assets/inmemory/icon2.svg"></v-img>
                  <p class="txt" v-if="GET_LANG == 'en'">Versatility</p>
                  <p class="txt" v-if="GET_LANG == 'ru'">Универсальность</p>
                  <p class="txt2" v-if="GET_LANG == 'en'">Flexibility and convenience	of SQL-based relational approach</p>
                  <p class="txt2" v-if="GET_LANG == 'ru'">гибкость и удобство реляционного подхода на основе SQL</p>
                </div>
              </div>
              <div class="dignity-item">
                <div class="content">
                  <v-img class="top-img" width="146" src="../../assets/inmemory/icon1.svg"></v-img>
                  <p class="txt" v-if="GET_LANG == 'ru'">Быстродействие</p>
                  <p class="txt" v-if="GET_LANG == 'en'">Performance</p>
                  <p class="txt2" v-if="GET_LANG == 'ru'">на порядок выше конкурентов, сравнимое с проприетарными алгоритмами</p>
                  <p class="txt2" v-if="GET_LANG == 'en'">orders of magnitude superior to the closest competitors, comparable to custom proprietary algorithms</p>
                </div>
              </div>
              <div class="dignity-item">
                <div class="content">
                  <v-img class="top-img" width="146" src="../../assets/inmemory/icon3.svg"></v-img>
                  <p class="txt" v-if="GET_LANG == 'ru'">Безопасность</p>
                  <p class="txt" v-if="GET_LANG == 'en'">Fault tolerance</p>
                  <p class="txt2" v-if="GET_LANG == 'ru'">устойчивость к сбоям, асинхронное сохранение результатов на диск</p>
                  <p class="txt2" v-if="GET_LANG == 'en'">Saves to disk all current results asynchronously</p>
                </div>
              </div>
            </div>
            <div class="dignity-container2">
              <div class="item">
                <p class="txt" v-if="GET_LANG == 'en'">Unlimited Volume</p>
                <p class="txt" v-if="GET_LANG == 'ru'">Отсутствие ограничений</p>
                <p class="txt2" v-if="GET_LANG == 'en'">The data volume is limited only by disk and memory resources. </p>
                <p class="txt2" v-if="GET_LANG == 'ru'">Объем хранимых данных ограничен лишь ресурсами диска и памяти.</p>
              </div>
              <div class="item">
                <p class="txt" v-if="GET_LANG == 'en'">Great scalability</p>
                <p class="txt" v-if="GET_LANG == 'ru'">Беспрецедентная скорость</p>
                <p class="txt2" v-if="GET_LANG == 'en'">NitrosBase scales from mobile devices to thousands processors in a cluster</p>
                <p class="txt2" v-if="GET_LANG == 'ru'">NitrosBase InMemory DB может служить СУБД реального времени для большинства приложений.</p>
              </div>
            </div>
            <p v-if="GET_LANG == 'ru'" class="txt-bot">
              Технология ориентируется на параллельные<br> алгоритмы, что делает ее прекрасно<br> масштабируемой: от мобильных устройств -<br> до десятков тысяч процессоров в кластере
            </p>
            <p v-if="GET_LANG == 'en'" class="txt-bot">
              The performance of NitrosBase InMemory DB<br> is so high that it can be used<br> as a real-time database<br> for most applications.
            </p>
          </div>
          <!-- 
           -->
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center py-15">
        <v-col cols="8" class="pa-0 d-flex flex-column">
          <h2 v-if="GET_LANG == 'ru'" class="new-title">Новые возможности обработки данных</h2>
          <h2 v-if="GET_LANG == 'en'" class="new-title">New algorithms for data processing</h2>
          <p v-if="GET_LANG == 'ru'" class="new-text mt-11 ">Время выполнения запроса в логарифмическом масштабе</p>
          <p v-if="GET_LANG == 'en'" class="new-text mt-11">Query execution time in logarithmic scale</p>
          <!-- <div class="d-flex justify-space-between mt-16"> -->
          <div class="d-flex mt-16">
            <v-col cols="6">
              <div class="chart-container">
                <p class="num">10000</p>
                <p class="num">1000</p>
                <p class="num">100</p>
                <p class="num">10</p>
                <p class="num">1</p>
                <p class="query">Запрос 1</p>
                <p class="query">Запрос 2</p>
                <p class="query">Запрос 3</p>
                <p class="query">Запрос 4</p>
                <p class="query">Запрос 5</p>
                <v-img height="290" class="chart-img" src="../../assets/inmemory/chart.png"></v-img>
                <!-- <img class="chart-img" src="../../assets/inmemory/chart.png" alt="img"> -->
              </div>  
            </v-col>
            <v-col cols="6">
              <v-table class="table-container">
                <thead>
                  <tr>
                    <th></th>
                    <th>NitrosBase (ns)</th>
                    <th>TimesTen (ns)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in tblList"
                    :key="item.name"
                  >
                    <td>{{ item.query }}</td>
                    <td>{{ item.NitrosBase }}</td>
                    <td>{{ item.TimesTen }}</td>
                  </tr>
                </tbody>
              </v-table>
              <div class="desc-container">
                <div class="item">
                  <div class="azure"></div>
                  <p>NitrosBase</p>
                </div>
                <div class="item">
                  <div class="black"></div>
                  <p>NitrosBase</p>
                </div>
              </div>              
            </v-col>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center py-15 inmemory-architecture ">
        <div class="bg-img"></div>
        <v-col cols="8" class="pa-0 d-flex flex-column">
          <h2 v-if="GET_LANG == 'ru'" class="architecture-title">Архитектура</h2>
          <h2 v-if="GET_LANG == 'en'" class="architecture-title">Architecture</h2>
          <v-list class="d-flex justify-space-between arch-list">
            <v-list-item                   
              v-for="n,i in listArchitecture" 
              :key="n"
              width="17%"
              class="arch-item"
              >
              <!-- height="190" -->
              <!-- <v-img width="102" :src="'../../assets/inmemory/icon'+i+4+'.svg'" class="img-arc"></v-img> -->
              <img :src="getImg(i)" alt="">
              <p>{{ n }}</p>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center bg-lgrey">
        <v-col cols="8" class="py-16 px-0">
          <formContact />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import formContact from "../../components/formContact/formContact.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import "./index.scss";

export default {
    components: { formContact },
    name: 'im',
    computed: {
      ...mapGetters(['GET_LANG']),
      listDignity() {
        return this.GET_LANG == 'en' ? this.listDignityEn : this.listDignityRu
      },
      tblList() {
        return this.GET_LANG == 'en' ? this.tblListEn : this.tblListRu
      }
    },
    data() {
      return {
        listDignityEn:[
          {p1: 'Versatility',p2: 'Flexibility and convenience of SQL-based relational approach'},
          {p1: 'Performance',p2: 'orders of magnitude superior to the closest competitors, comparable to custom proprietary algorithms'},
          {p1: 'Fault tolerance',p2: 'Saves to disk all current results asynchronously'},
        ],
        listDignityRu:[
          {p1: 'Универсальность',p2: 'гибкость и удобство реляционного подхода на основе SQL'},
          {p1: 'Быстродействие',p2: 'на порядок выше конкурентов, сравнимое с проприетарными алгоритмами'},
          {p1: 'Безопасность',p2: 'устойчивость к сбоям асинхронное сохранение результатов на диск'},
        ],
        tblListRu: [
          {query: 'Запрос 1',NitrosBase: '12',TimesTen: '3000'},
          {query: 'Запрос 2',NitrosBase: '37',TimesTen: '3000'},
          {query: 'Запрос 3',NitrosBase: '51',TimesTen: '5000'},
          {query: 'Запрос 4',NitrosBase: '61',TimesTen: '3000'},
          {query: 'Запрос 5',NitrosBase: '49',TimesTen: '7000'}
        ],
        tblListEn: [
          {query: 'Query 1',NitrosBase: '12',TimesTen: '3000'},
          {query: 'Query 2',NitrosBase: '37',TimesTen: '3000'},
          {query: 'Query 3',NitrosBase: '51',TimesTen: '5000'},
          {query: 'Query 4',NitrosBase: '61',TimesTen: '3000'},
          {query: 'Query 5',NitrosBase: '49',TimesTen: '7000'}
        ],
        listArchitecture: [
          'Cуществует в виде библиотек под Windows и Linux.',
          'Pеляционная модель данных. Основной язык - SQL.',
          'Доступ к данным возможен через C/C++ API',
          'Утилита управления на основе CLI'
        ]
      }
    },
    methods: {
    ...mapActions(['SET_LANG','setPage']),
    changePage(page) {
      this.$store.commit('SET_PAGE',page)
    },
    getImg(i) {
      return require(`../../assets/inmemory/icon${i+4}.svg`)
    }
  }
}
</script>

<style>

</style>