<template>
  <div class="contacts pa-0">
		<v-row>
			<v-col class="pt-0">
				<p v-if="lang == 'ru'" class="font-weight-thin">Стоимость ПО определяется по запросу клиента, в зависимости от запрашиваемых характеристик. По вопросу приобртения ПО NitrosBase SQL просьба связаться с нами по E-Mail sales@nitrosbase.ru или заполнить форму ниже.</p>
				<p v-if="lang == 'en'" class="font-weight-thin">The cost of software is determined at the request of the client, depending on the requested characteristics. To purchase NitrosBase Multimodel software, please contact us by E-Mail sales@nitrosbase.ru or fill out the form below.</p>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="pt-0">
				<h1 v-if="lang == 'ru'" class="text-red text-h4 font-weight-thin">Связаться с нами</h1>
				<h1 v-if="lang == 'en'" class="text-red text-h4 font-weight-thin">Contact us</h1>
			</v-col>
		</v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        class="d-flex flex-column"
      >
        <v-text-field
          density="compact"
          variant="outlined"
          v-model="email"
          :rules="emailRules"
          :counter="10"
          label="Email"
          required
          hide-details
          bg-color="white"
        ></v-text-field>
        <v-text-field
          v-if="lang == 'en'"
          density="compact"
          variant="outlined"
          v-model="firstname"
          :rules="nameRules"
          :counter="10"
          label="Name"
          required
          hide-details
          bg-color="white"
          class="mt-5"
        ></v-text-field>
        <v-text-field
          v-if="lang == 'ru'"
          density="compact"
          variant="outlined"
          v-model="firstname"
          :rules="nameRules"
          :counter="10"
          label="Имя"
          required
          hide-details
          bg-color="white"
          class="mt-5"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
      <v-textarea
        v-if="lang == 'en'"
        bg-color="white"
        color="black"
        label="Message"
        variant="outlined"
        no-resize
      ></v-textarea>
      <v-textarea
        v-model="message"
        v-if="lang == 'ru'"
        bg-color="white"
        color="black"
        label="Сообщение"
        variant="outlined"
        no-resize
      ></v-textarea>
      </v-col>
      <v-col cols="6" class="pt-0">
				<v-checkbox
					v-model="checkbox"
					:label= "lang == 'ru' ?  'Отправляя сообщение, я подтверждаю свое согласие на обработку моих персональных данных, указанных в форме, в целях и пределах, установленных законодательством РФ о персональных данных.' : 'By sending this message, I confirm my consent to the processing of my personal data specified in the form, for the purposes and extent established by the legislation of the Russian Federation on personal data.'"
					required
          class="my-checkbox"
					></v-checkbox>
					<!-- :error-messages="v$.checkbox.$errors.map(e => e.$message)" -->
					<!-- @change="v$.checkbox.$touch"
					@blur="v$.checkbox.$touch" -->
        <v-btn :disabled = disableBtn v-if="lang == 'en'" type="submit" class="mt-5" @click="SentMail()">Sent</v-btn>
        <v-btn :disabled = disableBtn v-if="lang == 'ru'" type="submit" class="mt-5" @click="SentMail()">Отправить</v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="isActive"
      width="70%"
      >
      <!-- activator="parent" -->
      <template v-slot:default="{ isActive }">
        <v-card v-if="lang == 'ru'" title="Спасибо">
          <v-card-text>
            Мы получили ваше сообщение и ответим вам на указанный email.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text="Вернуться на главную страницу"
              @click="isActive.value = false"
            ></v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="lang == 'en'" title="Thank You">
          <v-card-text>
            We've received your message and will get back to you.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text="Back to home page"
              @click="isActive.value = false"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </template>            
    </v-dialog> 
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions, mapState } from "vuex";
import "./formContact.scss"
export default {
  name: 'formContact',
	computed: {
    ...mapGetters(['CURENT_LANG','TITELS']),
    ...mapState(['lang','page']),
    disableBtn() {
      if (this.checkbox && this.firstname != '' && this.email != '') {
        return false
      } else {
        return true
      }
    },
  },
	data() {
		return {
      isActive: false,
			valid: false,
			firstname: '',
      nameRules: [
        value => {
          if (value) return true

          return 'Name is required.'
        },
        value => {
          if (value?.length <= 10) return true

          return 'Name must be less than 10 characters.'
        },
      ],
      email: '',
      emailRules: [
        value => {
          if (value) return true

          return 'E-mail is requred.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail must be valid.'
        },
      ],
			checkbox: null,
      message: ''
		}
	},
  methods: {
    SentMail() {
      // console.log('SentMail Mail: ', this.email);
      // console.log('SentMail Name: ', this.firstname);
      // console.log('SentMail Message: ', this.message);
      let url = 'https://NitrosDataAPI.nitrosbase.com/API/SendContactMail'
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      let data = {
        email: this.email,
        name: this.firstname,
        text: this.message,
      }
      
     axios.post(url, data,{headers: headers})
       .then((response) => {
           console.log(response);
           this.isActive = true
       })
       .catch((error) => {
         console.log(error.message);
        //  this.isActive = true
       });
    },
  }
}
</script>

<style>

</style>